import axios from "axios";
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

import Echo from "laravel-echo";

window.env = window.env || {};
const settings = {
    VITE_PUSHER_APP_KEY:
        window.env.VITE_PUSHER_APP_KEY ||
        import.meta.env.VITE_PUSHER_APP_KEY ||
        "",
    VITE_PUSHER_APP_CLUSTER:
        window.env.VITE_PUSHER_APP_CLUSTER ||
        import.meta.env.VITE_PUSHER_APP_CLUSTER ||
        "",
    VITE_PUSHER_PORT:
        window.env.VITE_PUSHER_PORT || import.meta.env.VITE_PUSHER_PORT || "",
    VITE_PUSHER_SCHEME:
        window.env.VITE_PUSHER_SCHEME ||
        import.meta.env.VITE_PUSHER_SCHEME ||
        "",
};

import Pusher from "pusher-js";
window.Pusher = Pusher;
const options = {
    broadcaster: "pusher",
    key: settings.VITE_PUSHER_APP_KEY,
    wsHost: `ws-${settings.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: settings.VITE_PUSHER_PORT ?? 80,
    wssPort: settings.VITE_PUSHER_PORT ?? 443,
    forceTLS: (settings.VITE_PUSHER_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
};
window.Echo = new Echo(options);

import { createApp } from "vue";
const app = createApp({});

import lang from "./lang";
app.config.globalProperties.__ = lang;

import { Loader } from "@googlemaps/js-api-loader";
window.loader = new Loader({
    apiKey: "AIzaSyDGsNhQ_o5KNOtcSVmAsuh7GeGGkkaYT7c",
    libraries: ["places"],
});

import BorderGatewaySearch from "../components/BorderGatewaySearch.vue";
import TripSearch from "../components/TripSearch.vue";
import ContactForm from "../components/ContactForm.vue";
import Login from "../components/Auth/Login.vue";
import Register from "../components/Auth/Register.vue";
import ReservationList from "../components/Account/ReservationList.vue";
import ReviewerViewForm from "../components/Account/ReviewerViewForm.vue";
import Form from "../components/Reservation/Form.vue";
import ShipmentForm from "../components/Shipment/Form.vue";
import ShipmentList from "../components/Account/ShipmentList.vue";
app.component("border-gateway-search", BorderGatewaySearch);
app.component('trip-search', TripSearch);
app.component('contact-form', ContactForm);
app.component('login', Login);
app.component('register', Register);
app.component('reservation-list', ReservationList);
app.component('reviewer-view-form', ReviewerViewForm);
app.component('reservation-form', Form);
app.component('shipment-list', ShipmentList);
app.component('shipment-form', ShipmentForm);
if (document.getElementById("app")) {
    app.mount('#app');
}
